/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  background-color: white;
}

:root {
  --mdc-list-list-item-label-text-weight: 100;
  --mdc-typography-button-letter-spacing: 'normal';
  --mdc-text-button-label-text-tracking: 'normal';
  --mdc-filled-button-label-text-tracking: 'normal';
  --mdc-filled-text-field-hover-active-indicator-color: #2ecbfe;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ecbfe;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #2ecbfe;
}

#valikko .mat-mdc-nav-list .mat-mdc-list-item {
  height: 55px;
  line-height: normal;
  font-weight: 100;
  font-size: 16px;
}

#valikko .mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon {
  margin-right: 2px;
  color: white;
}

#valikko .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__primary-text {
  white-space: normal;
}

#valikko .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  text-align: left;
}

#valikko .mdc-list-item:focus::before {
  background-color: unset;
  opacity: unset;
}

.mat-mdc-button .icon {
  vertical-align: middle;
}

.kokosivu .backdrop,
.kokosivu .spinner-three-bounce {
  position: fixed;
}

.ostotositteet-listaus-page .maksutapa-container.over {
  background-color: inherit;
  cursor: pointer;
}

.ostotositteet-listaus-page .maksutapa-container .content {
  display: block;
  height: auto;
}

.hide,
.hide * {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.list-page,
.detail-page {
  margin: 0;
  padding: 3vh 4vw;
  max-width: 1400px;
}

.list-page .mat-mdc-row,
.list-page .mat-mdc-cell {
  cursor: pointer;
}

/* TREE */
.mat-tree .mat-mdc-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.mat-tree .mat-tree-node {
  min-height: 30px;
}

/* DIALOGIT */
.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

html .mdc-dialog mat-dialog-content.mat-mdc-dialog-content,
html .mdc-dialog .mdc-dialog__content {
  padding: 0 30px 10px;
  /* Check lemon-dialog-header button right value, minkä pitää olla sama kuin padding-right */
  margin: 0;
}

mat-dialog-actions.mat-mdc-dialog-actions,
div.mat-mdc-dialog-actions {
  padding: 30px;
  justify-content: center;
  margin: 0;
}

mat-dialog-container.mat-mdc-dialog-container,
div.mat-mdc-dialog-container {
  padding: 0;
  /* border-radius: 0 !important; */
}

.info-dialog-text {
  text-align: left;
}

/* LOADING SPINNER */
.kokosivu .backdrop {
  position: fixed !important;
}

.backdrop {
  animation: lemon-tummenna-tausta 0.2s ease-in-out 0s 1 normal both running !important;
}

@keyframes lemon-tummenna-tausta {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes lemon-vaalenna-tausta {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.spinner-three-bounce {
  padding-top: 100px !important;
}

/* VALIKKO */
button.vasemmanValikonAukaisija {
  position: absolute;
}

@media (max-width: 600px) {

  .list-page,
  .detail-page {
    padding: 0 4vw 4vw 4vw;
  }

  button.vasemmanValikonAukaisija {
    position: relative;
  }
}

.auki {
  display: grid;
  grid-template-columns: 220px 1fr;
}

#valikko {
  width: 220px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  text-align: center;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

#valikko [matlistitemicon] {
  width: 26px;
  height: 26px;
  font-size: 26px;
  padding: 3px 12px 6px 8px;
}

#valikko .mdc-list-item__primary-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
}

#valikko .mat-mdc-list-item {
  height: 54px;
}

/** FORMIT **/
html .mdc-text-field:not(.mdc-text-field--outlined) {
  overflow: visible;
  padding: 0px;
  margin-top: -10px;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset;
}

html .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset;
}

html .mat-mdc-form-field-focus-overlay {
  display: none;
}

html .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 34px;
}

html .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
  top: 28px;
}

html .mat-mdc-form-field-hint-wrapper,
html .mat-mdc-form-field-error-wrapper {
  padding: 0;
}

form mat-form-field.mat-mdc-form-field {
  width: 100%;
}

html body div mat-form-field .mat-mdc-form-field-infix {
  width: auto;
  min-height: 50px;
  padding-bottom: 0px !important;
}

html .mat-mdc-form-field-icon-prefix>.mat-icon {
  padding: 0;
  margin-top: 15px;
}

/** See PlaceholderWithRequiredIndicatorDirective for more details */
.mat-mdc-form-field-infix:not(:has(label)) .mat-mdc-select-required .mat-mdc-select-placeholder:after {
  content: " *";
}

html .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(-2px);
}

html .mat-mdc-form-field-icon-suffix {
  height: 30px;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #2ecbfe;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #2ecbfe;
}

html .mat-mdc-form-field {
  width: 100%;
  height: fit-content;
}

html mat-form-field.no-outline.no-margin {
  margin-top: -15px;
  margin-bottom: -15px;
}

html mat-form-field.no-outline .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
html mat-form-field.no-outline .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
html mat-form-field.no-outline .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
html mat-form-field.no-outline .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::after {
  border-bottom-color: transparent;
}

html .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 22px;
}

html .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgb(0, 0, 0)
}

html .mat-mdc-form-field.mat-mdc-paginator-page-size-select {
  width: 80px;
}

html .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mdc-notched-outline--no-label .mdc-notched-outline__leading,
html .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mdc-notched-outline--no-label .mdc-notched-outline__trailing {
  border: unset;
}

html .mat-mdc-radio-button .mdc-radio {
  padding: 5px;
}

html .mat-mdc-form-field.ilman-otsaketta .mat-mdc-form-field-infix {
  border-top: none;
}

html .mat-datepicker-toggle .mat-mdc-button-persistent-ripple {
  display: none;
}

html .mat-datepicker-toggle .mdc-icon-button {
  padding: 0;
  width: 15px;
}

html .mat-datepicker-toggle .mdc-icon-button .mat-datepicker-toggle-default-icon {
  height: 18px;
  margin-left: -10px;
  margin-top: 5px;
}

.list-page .ylaosan-kontrollit {
  margin-bottom: 1em;
  display: flex;
  flex-flow: row wrap;
}

.hakukontrollit {
  display: flex;
  flex-flow: row wrap;
}

.lisaa-nappula-container {
  align-self: flex-end;
}

.flashit {
  -webkit-animation: flash linear 2s infinite;
  animation: flash linear 2s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

body .kuukausi-valitsin .mat-calendar-period-button {
  pointer-events: none;
}

body .kuukausi-valitsin .mat-calendar-arrow {
  display: none;
}

/* LASKUN TUOTTEET */
#tuotteet .mat-mdc-form-field-underline,
#tuotteet .mat-mdc-form-field-disabled .mat-mdc-form-field-underline {
  display: none;
}

#tuotteet .mat-mdc-form-field-placeholder {
  font-size: 11px;
  margin-top: -2px;
  left: 2px;
}

#tuotteet .mat-mdc-form-field-wrapper {
  padding-bottom: 0px;
}

#tuotteet .mat-mdc-form-field-suffix {
  padding-left: 0;
  padding-right: 3px;
}

#tuotteet .mat-mdc-form-field-flex {
  background-color: #ffffff;
  transform: background-color;
  color: #000000;
  border: 1px solid #fff;
  margin: 0;
  border-radius: 0;
}

#tuotteet .mat-mdc-form-field-infix {
  font-size: 11px;
  border: 0;
  padding: 2px;
  margin: 0;
}

#tuotteet .lemon-cell .ng-touched.ng-invalid .mat-mdc-form-field-wrapper {
  border-color: #f44336;
  background-color: rgb(255, 243, 241);
}

#tuotteet .mat-mdc-form-field {
  line-height: 25px;
  min-width: unset;
}

@media screen and (min-width: 551px) {

  #tuotteet .mat-mdc-form-field-flex {
    border-radius: 5px;
  }

  body #tuotteet .mat-mdc-form-field-placeholder {
    font-size: 12px;
    left: 5px;
  }

  body #tuotteet .mat-mdc-form-field-infix {
    font-size: 12px;
    padding: 5px;
  }
}

/* CHIPS */
body .mat-mdc-chip:not(.mat-mdc-basic-chip) .mat-mdc-chip-remove {
  color: #2cc4f5;
  opacity: 0.6;
}

body .mat-mdc-chip:not(.mat-mdc-basic-chip) {
  background-color: #fff;
  color: #2cc4f5;
  border: 1px solid #2cc4f5;
}

body input.mat-mdc-chip-input {
  width: 99%;
}

body .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: unset;
}

#projektit .mat-mdc-form-field-underline {
  display: none;
}

/* AUTOCOMPLETE */

/** Enables the select panel to have larger with than the associated mat-form-field */
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mat-mdc-autocomplete-panel .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-option {
  min-height: unset;
}

body .mat-mdc-option {
  line-height: 32px;
  height: 32px;
  font-size: 14px;
}

.ilman-alaosaa .mat-mdc-form-field-wrapper {
  margin-bottom: -1.25em;
}

/* VALKOINEN FORM-FIELD */

.valkoinen .mat-mdc-select-arrow,
.valkoinen .mat-mdc-form-field-placeholder {
  color: rgba(255, 255, 255, 0.54);
}

.valkoinen .mat-focused .mat-mdc-form-field-placeholder {
  color: rgba(255, 255, 255, 0.74);
}

.mat-primary .valkoinen.mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled) {
  color: rgb(0, 0, 0);
}

.valkoinen,
.valkoinen input,
.valkoinen .mat-mdc-cell,
.valkoinen .mat-mdc-header-cell,
.valkoinen.mat-mdc-cell,
.valkoinen.mat-mdc-header-cell,
.valkoinen .mat-mdc-list .mat-mdc-list-item,
.valkoinen .mat-mdc-nav-list .mat-mdc-list-item,
.valkoinen .mat-mdc-selection-list .mat-mdc-list-item,
.valkoinen .mat-mdc-select-value-text,
.valkoinen.mat-focused .mat-mdc-form-field-placeholder,
.valkoinen.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.valkoinen .mdc-list-item:hover .mdc-list-item__primary-text,
.valkoinen .mdc-list-item:focus .mdc-list-item__primary-text,
.valkoinen .mdc-list-item__primary-text,
.valkoinen .mat-mdc-floating-label.mdc-floating-label mat-label,
.valkoinen .mat-mdc-button:not(:disabled) {
  color: rgb(255, 255, 255);
}

.valkoinen .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgb(255, 255, 255);
}

.valkoinen .mat-select-disabled .mat-mdc-select-value,
.valkoinen .mat-select-disabled .mat-mdc-select-value-text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.valkoinen ::placeholder {
  color: #f8f8ff;
}

.valkoinen input {
  transition: all 0.2s ease-in-out;
  outline: none;
  border: 1px solid rgb(62, 203, 244);
  background-color: rgb(61, 158, 196);
}

.valkoinen input:focus {
  box-shadow: 0 0 5px #f8f8ff;
  border: 1px solid #f8f8ff;
}

.pyoristettu-potko-select .mat-mdc-select-trigger,
.pyoristettu-potko-select .mat-mdc-form-field-placeholder {
  padding-left: 10px;
  padding-right: 10px;
}

.pyoristettu-potko-select .mdc-line-ripple {
  display: none;
}

.placeholder-isoilla .mat-form-field-placeholder {
  text-transform: uppercase;
}

/* RADIO */
body mat-radio-button label.mdc-label {
  font-weight: 500;
  white-space: nowrap;
}

body .mat-mdc-radio-label {
  padding: 5px;
}

body mat-radio-button.dense .mat-radio-label {
  padding: 0;
}

/* VÄRIT */
.blue-background {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2ecbfe+0,2ab8e6+100 */
  background: #2ecbfe;
  /* Old browsers */
  background: -moz-linear-gradient(top,
      #2ecbfe 0%,
      #2ab8e6 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #2ecbfe),
      color-stop(100%, #2ab8e6));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top,
      #2ecbfe 0%,
      #2ab8e6 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top,
      #2ecbfe 0%,
      #2ab8e6 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top,
      #2ecbfe 0%,
      #2ab8e6 100%);
  /* IE10 preview */
  background: linear-gradient(to bottom,
      #2ecbfe 0%,
      #2ab8e6 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2ecbfe', endColorstr='#2ab8e6', GradientType=0);
  /* IE6-9 */
}

.blue-background .mat-mdc-button.sulje {
  color: white;
}

.lemon-mat-elevation-z4 {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.05),
    0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}

.light-gray-background {
  background-color: lightgray;
}

.light-blue-background {
  background-color: rgb(62, 203, 244);
}

.dark-gray-text,
button.dark-gray-text,
[mat-button].mat-mdc-button-base.dark-gray-text,
textarea.dark-gray-text,
.tosite-api-msg {
  color: rgb(124, 124, 124);
}

.gray-text,
button.gray-text,
[mat-button].mat-mdc-button-base.gray-text,
textarea.gray-text {
  color: gray;
}

.light-gray-text,
button.light-gray-text,
[mat-button].mat-mdc-button-base.light-gray-text,
textarea.light-gray-text {
  color: lightgray;
}

.light-blue-text,
[mat-button].mat-mdc-button-base.light-blue-text,
textarea.light-blue-text,
.light-blue-text .mat-mdc-button.mdc-button,
.light-blue-text .mat-mdc-select-value {
  color: rgb(62, 203, 244);
}

.dark-purple-text,
button.dark-purple-text,
[mat-button].mat-mdc-button-base.dark-purple-text {
  color: #E362EB;
}

.blue-text,
.mat-mdc-cell.blue-text,
.mat-mdc-cell.blue-text,
button.blue-text,
[mat-button].mat-mdc-button-base.blue-text,
textarea.blue-text,
.blue-text .mat-mdc-select-value {
  color: #2a8ff4;
}

.yellow-text,
.mat-mdc-cell.yellow-text,
.mat-mdc-cell.yellow-text,
button.yellow-text,
[mat-button].mat-mdc-button-base.yellow-text,
textarea.yellow-text,
.yellow-text .mat-mdc-select-value {
  color: rgb(248, 203, 89);
}

.green-text,
.mat-mdc-cell.green-text,
.mat-mdc-cell.green-text,
button.green-text,
[mat-button].mat-mdc-button-base.green-text,
textarea.green-text,
.green-text .mat-mdc-select-value {
  color: #4bd76b;
}

.purple-text,
.mat-mdc-cell.purple-text,
.mat-mdc-cell.purple-text,
button.purple-text,
[mat-button].mat-mdc-button-base.purple-text,
textarea.purple-text,
.purple-text .mat-mdc-select-value {
  color: #f755f5;
}

.lemon-error,
.error-text,
.mat-mdc-cell.error-text,
.mat-mdc-cell.error-text,
button.error-text,
[mat-button].mat-mdc-button-base.error-text,
textarea.error-text,
.error-text .mat-mdc-select-value {
  color: #f44336;
}

.red-text,
.mat-mdc-cell.red-text,
.mat-mdc-cell.red-text,
button.red-text,
[mat-button].mat-mdc-button-base.red-text,
textarea.red-text,
.red-text .mat-mdc-select-value {
  color: rgb(255, 72, 102);
}

/* BUTTONS */
html .mat-mdc-button {
  height: auto;
  min-height: 36px;
  white-space: nowrap;
}

html .mat-mdc-icon-button.mat-mdc-button-base.large-icon-button {
  width: 38px;
  height: 38px;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

html .mat-mdc-icon-button.mat-mdc-button-base.large-icon-button .icon {
  font-size: 38px;
  line-height: 38px;
  width: 38px;
  height: 38px;
}

html .mat-mdc-icon-button.mat-mdc-button-base.large-icon-button .mat-mdc-button-touch-target {
  width: 38px;
  height: 38px;
}

html .mat-mdc-icon-button.mat-mdc-button-base.small-icon-button {
  width: 24px;
  height: 24px;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

html .mat-mdc-icon-button.mat-mdc-button-base.small-icon-button .icon {
  width: 23px;
  height: 23px;
  font-size: 23px;
  line-height: 23px;
}

html .mat-mdc-icon-button.mat-mdc-button-base.small-icon-button .mat-mdc-button-touch-target {
  width: 24px;
  height: 24px
}

button.padded {
  /** Sets the same horizontal padding as primary/secondary button classes */
  padding-left: 18px;
  padding-right: 18px;
}

button.primarybutton.mat-mdc-button-base.mat-unthemed,
button.secondarybutton.mat-mdc-button-base.mat-unthemed {
  letter-spacing: normal;
  font-weight: 500;
  background-color: #2cc4f5;
  color: white;
  border-radius: 25px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
}

button.secondarybutton.mat-mdc-button-base.mat-unthemed {
  background-color: white;
  color: #2cc4f5;
  border: 1px solid #2cc4f5;
}

button.primarybutton[disabled].mat-mdc-button-base.mat-unthemed {
  color: rgba(0, 0, 0, 0.26);
}

button.secondarybutton[disabled].mat-mdc-button-base.mat-unthemed {
  color: rgba(0, 0, 0, 0.26);
  border: 1px solid rgba(0, 0, 0, 0.26);
}

button.primarybutton[mat-icon-button].mat-mdc-button-base.mat-unthemed,
button.secondarybutton[mat-icon-button].mat-mdc-button-base.mat-unthemed {
  border-radius: 50%;
  padding-left: 0;
  padding-right: 0;
  width: 42px;
  height: 42px;
}

body .selectedmenu,
.selectedmenu.mat-mdc-list-item.mat-mdc-list-item-focus {
  background-color: rgb(42, 179, 222);
}

input[decimalNumberField] {
  text-align: right;
}

input[decimalNumberField].alignLeft {
  text-align: left;
}

input[autocomplete="off"]::-webkit-credentials-auto-fill-button,
input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autocomplete="kirjanpitoaineistoarkki"]::-webkit-credentials-auto-fill-button,
input[autocomplete="kirjanpitoaineistoarkki"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/* TÄMÄ ALAPUOLELLA ON ASIAKASLISTAUKSEN TYYLIT!!! */
.a-l-r {
  position: relative;
  width: 50px;
  height: 50px;
  font-size: 10px;
  line-height: 10px;
}

.a-l-r-s-a1,
.a-l-r-s-a2,
.a-l-r-s-a3,
.a-l-r-s-a4,
.a-l-r-s-a5,
.a-l-r-s-a6 {
  position: absolute;
  display: none;
}

.a-l-r-email-lah .a-l-r-s-a1 {
  display: inline-block;
  top: 5px;
  left: 5px;
  color: #4bd76b;
}

.a-l-r-email-lah .a-l-r-s-a1::before {
  content: "✓";
  /* ✔ */
  font-size: 16px;
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
}

.a-l-r-alvvih.a-l-r-email-lah .a-l-r-s-a1::before {
  font-size: 12px;
}

.a-l-r-alvmus .a-l-r-s-a1,
.a-l-r-alvora .a-l-r-s-a1,
.a-l-r-alvhar .a-l-r-s-a1,
.a-l-r-alvvih .a-l-r-s-a1,
.a-l-r-alvpun .a-l-r-s-a1,
.a-l-r-alvsin .a-l-r-s-a1 {
  color: white;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  display: inline-block;
  top: 5px;
  left: 5px;
}

.a-l-r-alvmus .a-l-r-s-a1::before,
.a-l-r-alvora .a-l-r-s-a1::before,
.a-l-r-alvpun .a-l-r-s-a1::before {
  content: "!";
  display: inline-block;
  width: 16px;
  line-height: 14px;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
}

.a-l-r-alvpun .a-l-r-s-a1 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.a-l-r-alvpun .a-l-r-s-a1::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  font-size: 14px;
}

.a-l-r-alvpun .a-l-r-s-a1 {
  background-color: rgb(255, 72, 102);
}

.a-l-r-alvhar .a-l-r-s-a1 {
  background-color: rgb(229, 229, 229);
}

.a-l-r-alvora .a-l-r-s-a1 {
  background-color: rgb(240, 146, 66);
}

.a-l-r-alvmus .a-l-r-s-a1 {
  background-color: black;
}

.a-l-r-alvvih .a-l-r-s-a1 {
  background-color: #4bd76b;
}

.a-l-r-alvsin .a-l-r-s-a1 {
  background-color: rgb(0, 65, 245);
}

.a-l-r-n-h .a-l-r-s-a1 {
  display: inline-block;
  top: 6px;
  left: 6px;
}

.a-l-r-ei-aktiivinen .a-l-r-s-a2::before {
  content: "Ei akt.";
  color: gray;
}

.a-l-r-ei-aktiivinen .a-l-r-s-a2,
.a-l-r-va-selvitettavat .a-l-r-s-a2,
.a-l-r-va-tehot .a-l-r-s-a2,
.a-l-r-va-minuutit .a-l-r-s-a2,
.a-l-r-ok .a-l-r-s-a2,
.a-l-r-po .a-l-r-s-a2,
.a-l-r-t .a-l-r-s-a2 {
  display: inline-block;
  bottom: 2px;
  left: 2px;
}

.a-l-r-va-sinikyna .a-l-r-s-a2,
.a-l-r-va-sininuija .a-l-r-s-a2,
.a-l-r-va-sinisuurennuslasi .a-l-r-s-a2,
.a-l-r-va-heittaa .a-l-r-s-a2,
.a-l-r-va-odottaa-osinko-rekisterointia .a-l-r-s-a2 {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  bottom: 4px;
  left: 6px;
  font-size: 14px;
  line-height: 14px;
}

.a-l-r-va-oranssikyna .a-l-r-s-a2 {
  font-weight: bold;
  display: inline-block;
  bottom: 1px;
  left: 2px;
  font-size: 11px;
  line-height: 11px;
}

.a-l-r-va-selvitettavat .a-l-r-s-a2 {
  color: #ea1313;
}

.a-l-r-va-sinikyna .a-l-r-s-a2,
.a-l-r-va-sininuija .a-l-r-s-a2,
.a-l-r-va-sinisuurennuslasi .a-l-r-s-a2 {
  color: #06A1C2;
}

.a-l-r-va-oranssikyna .a-l-r-s-a2 {
  color: #CE6A33;
}

.a-l-r-va-sinikyna .a-l-r-s-a2 {
  color: #06A1C2;
}

.a-l-r-va-oranssikyna .a-l-r-s-a2::before {
  content: "PRH";
}

/** NOTE! DO NOT TOUCHE THE SQUARE, IT IS FONT AWESOME FONT &#xf040; or fa fa-pencil */
.a-l-r-va-sinikyna .a-l-r-s-a2::before {
  content: "";
}

/** NOTE! DO NOT TOUCHE THE SQUARE, IT IS FONT AWESOME FONT &#xf0e3; or fa fa-gavel */
.a-l-r-va-sininuija .a-l-r-s-a2::before {
  content: "";
}

/** NOTE! DO NOT TOUCHE THE SQUARE, IT IS FONT AWESOME FONT &#xf002; or fa fa-search */
.a-l-r-va-sinisuurennuslasi .a-l-r-s-a2::before {
  content: "";
}

/** NOTE! DO NOT TOUCHE THE SQUARE, IT IS FONT AWESOME FONT &#xf071; or fa fa-exclamation-triangle */
.a-l-r-va-heittaa .a-l-r-s-a2::before {
  color: #CE6A33;
  content: "";
}

/** NOTE! DO NOT TOUCHE THE SQUARE, IT IS FONT AWESOME FONT &#xf153; or fa fa-eur */
.a-l-r-va-odottaa-osinko-rekisterointia .a-l-r-s-a2::after {
  color: #ea1313;
  content: " ";
}

.a-l-r-viesti .a-l-r-s-a3 {
  display: inline-block;
  top: 2px;
  right: 2px;
  font-size: 16px;
  color: #8b8b8b;
  z-index: 5;
}

.a-l-r-tosh .a-l-r-s-a4,
.a-l-r-toss .a-l-r-s-a4,
.a-l-r-tosp .a-l-r-s-a4,
.a-l-r-tospuusi .a-l-r-s-a4 {
  display: inline-block;
  bottom: 2px;
  right: 2px;
}

.a-l-r-toss .a-l-r-s-a4 {
  font-weight: bold;
  color: rgb(62, 203, 244);
}

.a-l-r-tosh .a-l-r-s-a4 {
  color: rgb(100, 100, 100);
}

.a-l-r-tosp .a-l-r-s-a4 {
  color: rgb(255, 72, 102);
  font-weight: bold;
}

.a-l-r-tospuusi .a-l-r-s-a4 {
  background-color: rgb(255, 72, 102);
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  font-size: 11px;
  font-weight: bold;
}

.a-l-r-s-a5 {
  bottom: 2px;
  right: 2px;
  left: 2px;
  top: 2px;
  line-height: 46px;
  text-align: center;
  font-size: 25px;
}

/* .a-l-r-et .a-l-r-s-a5 {
  display: inline-block;
  font-size: 20px;
} */
.a-l-r-td-ruutu.a-l-r-et,
.a-l-r-td-ruutu.a-l-r-etn {
  text-align: center;
}

.a-l-r-td-ruutu.a-l-r-etn::before {
  line-height: 12px;
  font-size: 10px;
  content: "Ei\Atilikautta";
  color: #BEBEBE;
  white-space: pre-wrap;
}

.a-l-r-td-ruutu.a-l-r-et::before {
  line-height: 46px;
  font-size: 25px;
  content: "?";
}

.a-l-r-td-ruutu.a-l-r-pallurasin::before {
  line-height: 46px;
  font-size: 25px;
  content: "•";
}

.a-l-r-td-ruutu.a-l-r-pallurasin {
  text-align: center;
  color: rgb(0, 65, 245);
}

.a-l-r-td-ruutu.a-l-r-lukittu::before {
  line-height: 46px;
  font-size: 20px;
  content: "\f023";
  /* unicode of fa-lock or &#xf023; */
}

.a-l-r-td-ruutu.a-l-r-lukittu {
  text-align: center;
  display: table-cell;
}


.a-l-r-po .a-l-r-s-a2,
.a-l-r-t .a-l-r-s-a2,
.a-l-r-td-ruutu.a-l-r-lukittu {
  color: gray;
}

.a-l-r-td-ruutu.a-l-r-huutopun::before {
  line-height: 46px;
  font-size: 25px;
  content: "!";
}

.a-l-r-td-ruutu.a-l-r-huutopun {
  text-align: center;
  color: rgb(224, 48, 2);
}

.a-l-r-td-ruutu.a-l-r-huutomus::before {
  line-height: 46px;
  font-size: 25px;
  content: "!";
}

.a-l-r-td-ruutu.a-l-r-huutomus {
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.a-l-r-vih {
  background-color: rgb(188, 227, 174);
}

.a-l-r-pun {
  background-color: rgb(229, 162, 160);
}

.a-l-r-vih-uusi {
  background-color: #e3fce4;
}

.a-l-r-pun-uusi {
  background-color: #ffe6e5;
}

.a-l-r-kel-uusi {
  background-color: #fbfce3;
}

.a-l-r-kel {
  background-color: rgb(253, 252, 175);
}

.a-l-r-har {
  background-color: rgb(229, 229, 229);
}

.a-l-r-dis {
  background-color: rgb(185, 185, 185);
}

.a-l-r-sinr .a-l-r-s-a6 {
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 1px solid rgb(38, 189, 243);
  width: 48px;
  height: 48px;
  z-index: 4;
}

.a-l-r-punr .a-l-r-s-a6 {
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 1px solid rgb(255, 72, 102);
  width: 48px;
  height: 48px;
  z-index: 4;
}

/*
.al-v1 .a-l-r-s-a6 {
  left: -1px;
}
.al-v2 .a-l-r-s-a6 {
  left: -2px;
}
.al-v3 .a-l-r-s-a6 {
  left: -3px;
}
.al-v4 .a-l-r-s-a6 {
  left: -4px;
}
.al-v5 .a-l-r-s-a6 {
  left: -5px;
}
.al-v6 .a-l-r-s-a6 {
  left: -6px;
}
.al-v7 .a-l-r-s-a6 {
  left: -7px;
}
.al-v8 .a-l-r-s-a6 {
  left: -8px;
}
.al-v9 .a-l-r-s-a6 {
  left: -9px;
}
.al-v10 .a-l-r-s-a6 {
  left: -10px;
}
.al-v11 .a-l-r-s-a6 {
  left: -11px;
}
.al-v12 .a-l-r-s-a6 {
  left: -12px;
}
.al-v13 .a-l-r-s-a6 {
  left: -13px;
}
.al-v14 .a-l-r-s-a6 {
  left: -14px;
}
.al-v15 .a-l-r-s-a6 {
  left: -15px;
}
.al-v16 .a-l-r-s-a6 {
  left: -16px;
}
.al-v17 .a-l-r-s-a6 {
  left: -17px;
}
.al-v18 .a-l-r-s-a6 {
  left: -18px;
}
.al-v19 .a-l-r-s-a6 {
  left: -19px;
}
.al-v20 .a-l-r-s-a6 {
  left: -20px;
}
.al-v21 .a-l-r-s-a6 {
  left: -21px;
}
.al-v22 .a-l-r-s-a6 {
  left: -22px;
}
.al-v23 .a-l-r-s-a6 {
  left: -23px;
}
.al-v24 .a-l-r-s-a6 {
  left: -24px;
}
.al-v25 .a-l-r-s-a6 {
  left: -25px;
}
.al-v26 .a-l-r-s-a6 {
  left: -26px;
}
.al-v27 .a-l-r-s-a6 {
  left: -27px;
}
.al-v28 .a-l-r-s-a6 {
  left: -28px;
}
.al-v29 .a-l-r-s-a6 {
  left: -29px;
}
.al-v30 .a-l-r-s-a6 {
  left: -30px;
}
.al-v31 .a-l-r-s-a6 {
  left: -31px;
}
.al-v32 .a-l-r-s-a6 {
  left: -32px;
}
.al-v33 .a-l-r-s-a6 {
  left: -33px;
}
.al-v34 .a-l-r-s-a6 {
  left: -34px;
}
.al-v35 .a-l-r-s-a6 {
  left: -35px;
}
.al-v36 .a-l-r-s-a6 {
  left: -36px;
}
.al-v37 .a-l-r-s-a6 {
  left: -37px;
}
.al-v38 .a-l-r-s-a6 {
  left: -38px;
}
.al-v39 .a-l-r-s-a6 {
  left: -39px;
}
.al-v40 .a-l-r-s-a6 {
  left: -40px;
}
.al-v41 .a-l-r-s-a6 {
  left: -41px;
}
.al-v42 .a-l-r-s-a6 {
  left: -42px;
}
.al-v43 .a-l-r-s-a6 {
  left: -43px;
}
.al-v44 .a-l-r-s-a6 {
  left: -44px;
}
.al-v45 .a-l-r-s-a6 {
  left: -45px;
}
.al-v46 .a-l-r-s-a6 {
  left: -46px;
}
.al-v47 .a-l-r-s-a6 {
  left: -47px;
}
.al-v48 .a-l-r-s-a6 {
  left: -48px;
}
.al-v49 .a-l-r-s-a6 {
  left: -49px;
}
.al-v50 .a-l-r-s-a6 {
  left: -50px;
} */

/* ASIAKASLISTAUS TABLE */
.a-l-table {
  border-spacing: 0;
  /* table-layout: fixed; */
  cursor: pointer;
}

.a-l-table thead th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.a-l-r-td-eka,
.a-l-r-td-toka,
.a-l-r-td-sarake,
.a-l-r-td-kolmas,
.a-l-r-td-neljas,
.a-l-r-td-viides,
.a-l-r-td-ruutu,
.a-l-r-td-kuudes {
  padding: 0;
  border: solid lightgray;
  border-width: 0 0 1px 0;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.a-l-r-td-eka .mat-sort-header-container,
.a-l-r-td-viides .mat-sort-header-container {
  justify-content: flex-end;
}

.a-l-r-td-eka {
  text-align: right;
  padding-right: 18px;
}

.a-l-r-td-toka {
  padding-left: 5px;
}

.a-l-r-td-sarake {
  font-weight: bold;
  text-align: center;
  padding: 2px 0;
}

.a-l-r-td-kolmas {
  padding-left: 5px;
}

/* .a-l-r-td-neljas {
  text-align: center;
} */

.a-l-r-td-ruutu {
  width: 50px;
  border-width: 0 0 1px 1px;
}

.a-l-r-td-viides-c {
  position: relative;
}

.a-l-r-td-viides-h,
.a-l-r-td-viides-m {
  display: none;
}

.a-l-r-tr-o-m .a-l-r-td-viides-m {
  display: block;
  position: absolute;
  top: 3px;
  right: 0px;
  font-size: 10px;
  line-height: 10px;
  color: rgb(160, 160, 160);
}

.a-l-r-td-viides-s {
  line-height: 46px;
}

.a-l-r-tr-o-h .a-l-r-td-viides-h {
  display: block;
  position: absolute;
  bottom: 3px;
  right: 0px;
  font-size: 10px;
  line-height: 10px;
  color: rgb(160, 160, 160);
}

.a-l-r-td-viides {
  border-width: 0 0 1px 1px;
  text-align: right;
  padding-right: 18px;
}

.a-l-r-td-kuudes {
  text-align: left;
  padding-left: 5px;
  border-width: 0 0 1px 1px;
  white-space: nowrap;
  overflow-x: auto;
  text-overflow: initial;
  max-width: 500px;
}

.a-l-r-tr-pun .a-l-r-td-kuudes,
.a-l-r-tr-pun .sales-muistiinpanot {
  color: rgb(255, 72, 102);
  font-weight: bold;
}

.a-l-r-tr-sin .a-l-r-td-kuudes,
.a-l-r-tr-sin .sales-muistiinpanot {
  color: #2a8ff4;
  font-weight: bold;
}

.a-l-r-tr-vsi .a-l-r-td-kuudes,
.a-l-r-tr-vsi .sales-muistiinpanot {
  color: rgb(62, 203, 244);
  font-weight: bold;
}

.a-l-r-tr-kel .a-l-r-td-kuudes,
.a-l-r-tr-kel .sales-muistiinpanot {
  color: rgb(248, 203, 89);
  font-weight: bold;
}

.a-l-r-tr-vih .a-l-r-td-kuudes,
.a-l-r-tr-vih .sales-muistiinpanot {
  color: #4bd76b;
  font-weight: bold;
}

.a-l-r-tr-vio .a-l-r-td-kuudes,
.a-l-r-tr-vio .sales-muistiinpanot {
  color: #f755f5;
  font-weight: bold;
}

/**
  TABS
*/

html .mat-mdc-tab {
  letter-spacing: normal;
}

.mat-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

html .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  /** Override the default strected tabs logic to avoid setting the input programmatically. 
  * Should be removed when MAT_TABS_CONFIG support removing the stretch globally. */
  flex-grow: 0;
}

html .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
html .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
html .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  color: black;
}

.no-hidden-overflow-tabs .mat-mdc-tab-body-content,
.no-hidden-overflow-tabs .mat-tab-body,
.no-hidden-overflow-tabs .mat-tab-body.mat-tab-body-active,
.no-hidden-overflow-tabs .mat-tab-body-wrapper {
  overflow: unset;
}

.hide-all-overflows .mat-mdc-tab-body-content,
.hide-all-overflows .mat-tab-body,
.hide-all-overflows .mat-tab-body.mat-tab-body-active,
.hide-all-overflows .mat-tab-body-wrapper {
  overflow: hidden;
}

html .mdc-data-table__table {
  min-width: unset;
}

html body .mat-mdc-icon-button:hover .mat-mdc-button-focus-overlay {
  opacity: 0.04;
}

mat-tab-group.kirjaamattomat mat-tab-header {
  margin-right: 35px;
}

body .mat-mdc-menu-panel {
  max-width: 350px;
}

mat-checkbox label.mdc-label {
  font-weight: 500;
  white-space: nowrap;
}

mat-checkbox.pitka-label label.mdc-label,
mat-checkbox.keskitetty-label label.mdc-label {
  white-space: normal;
}

mat-checkbox.pitka-label label .mdc-checkbox {
  margin-top: 4px;
}

mat-checkbox.keskitetty-label label .mdc-checkbox {
  top: -5px;
}

.blink-asiakaslista-x {
  animation: xblinker 2s linear infinite;
}

@keyframes xblinker {
  50% {
    opacity: 0;
  }
}

.asiakaslista-ympyra,
.asiakaslista-ympyra-kirjain,
.asiakaslista-ympyra-ikoni,
.asiakaslista-ikoni {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 22px;
  font-size: 25px;
}

.asiakaslista-ikoni {
  font-size: 28px;
}

.asiakaslista-ympyra-kirjain {
  margin-left: -22px;
  font-size: 12px;
}

.asiakaslista-ympyra-ikoni {
  margin-left: -22px;
  font-size: 13px;
}

.a-l-r-po {
  background-color: #f8ead6;
}

.a-l-r-t {
  background-color: #e5e5e5;
}

/** MAT-CARD */
.mat-mdc-card.mdc-card {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  padding: 16px;
}

.mat-mdc-header-cell {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

html span[matsuffix] {
  position: relative;
  top: 25px;
}

html body #tuotteet .mat-mdc-form-field-underline,
html body #tuotteet .mat-mdc-form-field-disabled .mat-mdc-form-field-underline {
  display: none;
}

html body #tuotteet .mat-mdc-form-field-placeholder {
  margin-top: -2px;
  left: 2px;
}

html body #tuotteet .mat-mdc-text-field-wrapper {
  padding-bottom: 0px;
  margin-top: 0px;
}

html body #tuotteet .mat-mdc-form-field-suffix {
  padding-left: 0;
  padding-right: 3px;
}

html body #tuotteet mat-form-field.ng-invalid.ng-touched .mat-mdc-form-field-flex {
  border: 1px solid #f44336;
  border-radius: 0;
}

html body #tuotteet .mat-mdc-form-field-flex {
  background-color: #ffffff;
  transform: background-color;
  color: #000000;
  border: 1px solid #fff;
  margin: 0;
  border-radius: 0;
  height: 39px;
}

html body #tuotteet .mat-mdc-form-field-infix {
  border: 0;
  padding: 2px;
  margin: 0;
}

html body #tuotteet .mat-mdc-form-field {
  line-height: 25px;
  min-width: unset;
}

html body #tuotteet .mat-mdc-form-field,
html body #tuotteet .mat-mdc-select {
  font-size: 13px;
}

html body #tuotteet .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

html body #tuotteet .mat-mdc-text-field-wrapper .mdc-text-field {
  height: 39px;
}

@media screen and (min-width: 551px) {

  html body #tuotteet .mat-mdc-form-field-flex {
    border-radius: 5px;
  }

  html body #tuotteet .mat-mdc-form-field-placeholder {
    left: 5px;
  }

  html body #tuotteet .mat-mdc-form-field-infix {
    padding: 7px;
  }

}

body mat-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #61D263;
  --mdc-switch-selected-handle-color: white;
  --mdc-switch-selected-hover-state-layer-color: #61D263;
  --mdc-switch-selected-pressed-state-layer-color: #61D263;
  --mdc-switch-selected-focus-handle-color: white;
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-pressed-handle-color: white;
  --mdc-switch-selected-focus-track-color: #61D263;
  --mdc-switch-selected-hover-track-color: #61D263;
  --mdc-switch-selected-pressed-track-color: #61D263;
  --mdc-switch-selected-track-color: #61D263;
  --mdc-switch-selected-icon-color: white;
  --mdc-switch-selected-icon-opacity: 0;

  --mdc-switch-unselected-focus-handle-color: white;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: white;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: white;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-icon-opacity: 0;

  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-disabled-selected-icon-opacity: 0
}

.tosite-api-msg {
  font-style: italic;
}

.kuitti-laatikko .tosite-api-msg {
  font-size: 12px;
}